import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ExitIntent from '../components/ExitIntent';

import { Header, Footer, PageWrapper } from 'components/BusinessComponents';
import ArrowBack from 'assets/svgs/arrow_back.svg';
import SEO from 'components/SEO';
import { BREAKPOINTS, INTERNAL_LINKS, MOBILE_SIDE_PADDING } from 'utils/constants';

interface IImage {
  image: string;
}

export const query = graphql`
  query BusinessBlogQuery($id: String) {
    prismicBusinessBlogPage(id: { eq: $id }) {
      data {
        author_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        author_name {
          text
          html
          raw
        }
        author_quote {
          text
          html
          raw
        }
        og_description {
          text
          html
          raw
        }
        og_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        og_title {
          text
          html
          raw
        }
        page_description {
          text
          html
          raw
        }
        page_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        page_title {
          text
          html
          raw
        }
        post_content {
          text
          html
          raw
        }
        post_image {
          alt
          copyright
          gatsbyImageData(placeholder: BLURRED, height: 450)
        }
        post_title {
          text
          html
          raw
        }
        post_type {
          text
          html
          raw
        }
        twitter_description {
          text
          html
          raw
        }
        twitter_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        twitter_title {
          text
          html
          raw
        }
        post_date
        enable_pop_up
        body {
          ... on PrismicBusinessBlogPageDataBodyWithSecondDepth {
            slice_type
            items {
              second_popup_feature {
                richText
              }
            }
            primary {
              first_popup_confirm_button_text {
                richText
              }
              first_popup_deny_button_text {
                richText
              }
              first_popup_paragraph {
                richText
              }
              first_popup_subtext {
                richText
              }
              first_popup_title {
                richText
              }
              second_popup_button_text {
                richText
              }
              second_popup_paragraph {
                richText
              }
              second_popup_subtext {
                richText
              }
              second_popup_title {
                richText
              }
              second_popup_button_link {
                url
              }
              second_popup_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              second_popup_image_dark_close_button
            }
          }
          ... on PrismicBusinessBlogPageDataBodyClassic {
            slice_type
            primary {
              button_link {
                url
              }
              button_text {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              image_dark_close_button
              paragraph {
                richText
              }
              subtext {
                richText
              }
              title {
                richText
              }
            }
            items {
              feature {
                richText
              }
            }
          }
          ... on PrismicBusinessBlogPageDataBodyFeaturedOn {
            items {
              feature {
                richText
              }
            }
            slice_type
            primary {
              button_link {
                url
              }
              button_text {
                richText
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              image_dark_close_button
              paragraph {
                richText
              }
              subtext {
                richText
              }
              title {
                richText
              }
              featured_on_1 {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              featured_on_3 {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              featured_on_2 {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicBusinessBlogPageDataBodyLessVisuallyHeavy {
            items {
              solution_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              solution_label {
                richText
              }
            }
            primary {
              button_link {
                url
              }
              button_text {
                richText
              }
              paragraph {
                richText
              }
              subtext {
                richText
              }
              title {
                richText
              }
              featured_on_1 {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              featured_on_3 {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              featured_on_2 {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

const PageContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundWhite,
  color: props.theme.colors.dark,
  padding: '186px min(300px, 18%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
  letterSpacing: '0.01em',
  [BREAKPOINTS[800]]: {
    padding: `174px ${MOBILE_SIDE_PADDING}`,
  },
}));

const Container = styled.div({
  width: '100%',
  overflowX: 'hidden',
});

const BackButton = styled(Link)((props) => ({
  color: props.theme.colors.dark,
  textDecoration: 'none',
}));

const Title = styled.div({
  h1: {
    fontSize: 40,
    fontWeight: 600,
    margin: '56px 0 40px 0',
  },
});

const InfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 15,
  marginBottom: 48,
});

const TypeText = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightGray,
  padding: '2px 4px',
  borderRadius: 2,
}));

const Dot = styled.div({
  margin: '0 8px',
});

const StyledArrow = styled(ArrowBack)({
  marginRight: 5,
  marginBottom: 2,
});

const Dash = styled.div({
  width: 100,
  height: 0,
  borderBottom: '1px solid rgba(196, 196, 196, 0.7)',
  margin: '45px 0',
});

const AuthorContainer = styled.div({
  display: 'flex',
  [BREAKPOINTS[800]]: {
    flexDirection: 'column',
  },
});

const AuthorAvatar = styled.div<IImage>((props) => ({
  backgroundImage: `url(${props.image})`,
  backgroundPosition: 'center center',
  backgroundSize: 'auto 100%',
  backgroundRepeat: 'no-repeat',
  borderRadius: 45,
  marginRight: 24,
  height: 74,
  minWidth: 74,
  maxWidth: 74,
  [BREAKPOINTS[800]]: {
    marginBottom: 8,
  },
}));

const AuthorName = styled.div({
  fontSize: 15,
  marginBottom: 16,
  letterSpacing: '0.01em',
});

const AuthorQuote = styled.div({
  opacity: 0.7,
  fontStyle: 'italic',
  fontWeight: 100,
  fontSize: 15,
  lineHeight: '23px',
});

const ContentContainer = styled.div(({ theme }) => ({
  marginTop: 60,
  '> *': {
    overflowWrap: 'anywhere',
  },
  ['p, li, ol']: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 15,
    lineHeight: '27px',
    fontWeight: 300,
  },
  a: {
    color: theme.colors.linkText,
    transition: 'color 400ms',
    ':hover': {
      color: theme.colors.buttonBlueHover,
    },
  },
  h2: {
    fontWeight: theme.typography.fontWeights.semiBold,
    lineHeight: 1.5,
  },
  img: {
    maxWidth: '100%',
  },
}));

const BusinessBlogPage = ({ data }) => {
  const post = data.prismicBusinessBlogPage.data;

  const isAuthorAdded = !!post.author_image.url;
  const image = getImage(post.post_image);

  return (
    <div>
      <PageWrapper>
        <Header />
      </PageWrapper>
      <ExitIntent pageData={post} />
      <SEO
        pageTitle={post?.page_title?.text}
        ogTitle={post?.og_title?.text}
        twitterTitle={post?.twitter_title?.text}
        pageDescription={post?.page_description?.text}
        ogDescription={post?.og_description?.text}
        twitterDescription={post?.twitter_description?.text}
        pageImage={post?.page_image?.url}
        pageImageAlt={post?.page_image?.alt}
        twitterImage={post?.twitter_image?.url}
        twitterImageAlt={post?.twitter_image?.alt}
        ogImage={post?.og_image?.url}
        ogImageAlt={post?.og_image?.url?.alt}
      />
      <PageContainer>
        <Container>
          <BackButton to={INTERNAL_LINKS.BUSINESS_BLOG}>
            <StyledArrow /> BACK
          </BackButton>
          <Title>
            <RichText render={post.post_title?.raw} />
          </Title>
          <InfoContainer>
            {!!post.post_type?.text && <TypeText>{post.post_type?.text?.toUpperCase()}</TypeText>}
            <Dot>&bull;</Dot>
            <div>{post.post_date}</div>
          </InfoContainer>
          {image && (
            <GatsbyImage
              image={image}
              alt={post?.post_title?.text}
              style={{ borderRadius: 4, width: '100%' }}
              loading="lazy"
            />
          )}
          <ContentContainer>
            <RichText render={post.post_content?.raw} />
          </ContentContainer>
          {isAuthorAdded && (
            <>
              <Dash />
              <AuthorContainer>
                <AuthorAvatar image={post.author_image?.url} />
                <div>
                  <AuthorName>{post.author_name?.text?.toUpperCase()}</AuthorName>
                  <AuthorQuote>{post.author_quote?.text}</AuthorQuote>
                </div>
              </AuthorContainer>
            </>
          )}
        </Container>
      </PageContainer>
      <PageWrapper>
        <Footer />
      </PageWrapper>
    </div>
  );
};

export default BusinessBlogPage;
